<template>
  <div class="modify-container"><header-poster v-if="!screenWidthIsLe768()">
    <div class="header-poster-content">
      <div>ASCVTS 2024</div>
      <div>Welcome Message</div>
    </div>
  </header-poster>

    <div class="modify-content dk:p-4 mb:p-3">
      <div class="title pt-2 pb-3 mb-3 font-bold text-lg">
        Modify
      </div>
      <modify-info-form></modify-info-form>
    </div>
  </div>
</template>

<script>
  import { screenWidthIsLe768 } from "@/utils/util";
  export default {
    name: "ModifyInfo",
    components: {
      ModifyInfoForm: () => import('./modules/ModifyInfoForm'),
      HeaderPoster: () => import('../../components/HeaderPoster')
    },
    data() {
      return {
        screenWidthIsLe768
      }
    }
  }
</script>

<style lang="less" scoped>
@media screen and (min-width: 768px) {
  .modify-content {
    max-width: 768px;
    width: 768px;
    margin: 10px auto 60px auto!important;
  }
}
@media screen and (min-width: 820px) {
  .modify-content {
    max-width: 780px;
    width: 780px;
    margin: 10px auto 60px auto!important;
  }
}
@media screen and (min-width: 992px) {
  .modify-content {
    max-width: 900px;
    width: 900px;
    margin: 10px auto 60px auto!important;
  }
}
@media screen and (min-width: 1200px) {
  .modify-content {
    max-width: 1013px;
    width: 1013px;
    margin: 10px auto 60px auto!important;
  }
}
@media screen and (max-width: 767px) {
  .modify-content {
    width: 100%;
  }
}
.modify-container {
  background: #ffffff;
  /*margin-bottom: 40px;*/
  .header-poster-content {

    & > div:first-child {
      color: #F8B301;
    }
    & > div:last-child {
      color: white;
    }
  }
  .modify-content {
    background: #F5F7FA;
    .title {
      border-bottom: 1px solid #DEE2E6;
    }
    .account {
      background: white;
    }
  }
}
</style>
